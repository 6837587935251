import React from 'react';

function NoMatch() {

    return (
        <div className="no-route">
          <p>404 ROUTE NOT FOUND</p>
        </div>
    );
}


export default NoMatch;